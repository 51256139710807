.login_bac {
    background: url(../images/VtEaQF.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

/* Made with love by Mutiullah Samim*/


.card-body p{

    color:red;
  }
  /* .signup{
    height: 100% !important;
  } */
  .container {
    height: 100%;
    align-content: center;
    padding: 100px;
  }
  .setcontain {
    height: 100vh !important;
  }
  span.eyye {
    position: absolute;
    right: 20px;
    top: 12px;
    z-index: 999;
}
  
  .adduuser {
    /* height: 80vh !important; */
    padding: 28px;
  }

  
  
  
  .card{
  /* height: 370px; */
  margin-top: auto;
  margin-bottom: auto;
  width: 400px;
  background-color: rgba(0,0,0,0.5) !important;
  }
  
  .social_icon span{
  font-size: 60px;
  margin-left: 10px;
  color: #FFC312;
  }
  
  .social_icon span:hover{
  color: white;
  cursor: pointer;
  }
  
  .card-header h3{
  color: white;
  }
  
  .social_icon{
  position: absolute;
  right: 20px;
  top: -45px;
  }
  
  .input-group-prepend span{
  width: 50px;
  background-color: #FFC312;
  color: black;
  border:0 !important;
  }
  
  input:focus{
  outline: 0 0 0 0  !important;
  box-shadow: 0 0 0 0 !important;
  
  }
  
  .remember{
  color: white;
  }
  
  .remember input
  {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  margin-right: 5px;
  }
  
  .login_btn{
  color: black;
  background-color: #FFC312;
  /* width: 100px; */
  margin-top: 15px;

  }
  
  .login_btn:hover{
  color: black;
  background-color: white;
  }
  
  .links{
  color: white;
  }
  
  .links a{
  margin-left: 4px;
  }
  @media (max-width:600px) {
    .setcontain {
      padding: 16px !important;
    }
  
  }