@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

* {
  font-family: 'Poppins';
  font-weight: 800;
}

@font-face {
  font-family: 'Satoshi Regular';
  src: url("../fonts/Satoshi-Regular.otf") format("OpenType");
}

@font-face {
  font-family: 'Satoshi Bold';
  src: url("../fonts/Satoshi-Bold.otf") format("OpenType");
}

@font-face {
  font-family: 'Satoshi Medium';
  src: url("../fonts/Satoshi-Medium.otf") format("OpenType");
}

@font-face {
  font-family: 'Satoshi Black';
  src: url("../fonts/Satoshi-Black.otf") format("OpenType");
}

@font-face {
  font-family: "Clash Grotesk";
  src: url("./../../LandingPage/assets/ClashGrotesk-Medium.otf");
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container>input {
  width: 90% !important
}

.react-datepicker-popper {
  position: fixed !important;
}

.react-datepicker-popper {
  z-index: 1;
}