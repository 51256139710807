.editProfileSave {
  background: #3282b8;
  margin-top: 4%;
  border: none;
  width: 100%;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.editProfileButton {
  background: transparent;
  color: white;
  border: none;
  cursor: pointer;
}

.reviewedButton {
  padding: 8px !important;
  border: 1.5px solid black !important;
  margin-right: 10px !important;
}

.reviewedOnButton {
  background-color: #3282b8 !important;
  border: 1.5px solid #3282b8 !important;
  color: white !important;
}

.reviewButton {
  border: none;
  background: #3282b8;
  color: white;
  padding: 10px;
  border-radius: 6px;
  width: 100%;
  cursor: pointer;
}

.screenshotImage {
  height: 500px;
}

.searchTagFilter {
  padding: 8px !important;
  border: 1.5px solid black !important;
  border-radius: 6px !important;
  margin-right: 10px !important;
}

.paginationContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

ul {
  list-style: none;
}

@media screen and (max-width: 410px) {
  .slick-slide {
    display: block !important;
    /* justify-content: center !important; */
  }
}

.slide {
  width: 1000px !important;
  /* Change this value to adjust the slide width as per your requirement */
}

/* .slick-list {
  padding-bottom: 152px !important;
} */

.slick-next {
  right: 42% !important;
  top: auto !important;
  bottom: 32px !important;
}

.slick-prev {
  left: 45% !important;
  top: auto !important;
  bottom: 32px !important;
  z-index: 100 !important;
}

.slick-slide img {
  border-radius: 20px;
}

.slick-prev:before,
.slick-next:before {
  font-family: none !important;
}

.slick-slider.slick-initialized {
  /* height: 1200px; */
}

.slick-slide {
}

.imageUploaderLabel {
  border: 1px solid black;
  border-style: dotted;
  /* padding: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-weight: 400 !important;
  background: #f4f4f4;
  height: 200px;
  margin-bottom: 20px;
}

.selectedImageUpload {
  height: 200px;
  width: 100%;
  object-fit: cover;
  margin-bottom: 20px;
  border-radius: 8px;
}

.textAreaProduct {
  resize: none;
  width: 100%;
  height: 150px;
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid rgb(0 0 0 / 31%);
  font-weight: 300;
}

.addProductInput {
  border: none;
  border-bottom: 1px solid rgb(0 0 0 / 31%);
  width: 100%;
  font-weight: 300;
  margin-top: 20px;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.addProductInput:focus-visible,
.textAreaProduct:focus-visible {
  outline: none;
}

.reportPost {
  width: 100% !important;
  padding: 10px !important;
  margin-left: 0px !important;
}

.textAreaReport {
  resize: none;
  width: 100%;
  height: 150px;
  border-radius: 10px;
  padding: 10px;
}

.textAreaReport:focus-visible {
  outline: none;
}

.addReportButton {
  border: 1.5px solid black !important;
  padding: 8px !important;
  margin-left: 10px !important;
}

.deleteReportButton {
  padding: 8px !important;
  margin-left: 10px !important;
  background-color: red !important;
  color: white !important;
}

.comingSoon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5%;
}

.loginLogo {
  height: 100px;
  width: auto;
  margin-bottom: 15%;
}

.rememberMe {
  display: flex;
  justify-content: end;
  margin-bottom: 10%;
}
.forgot-password-div {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}
.forgot-password-text {
  color: #3282b8;
  cursor: pointer;
  height: fit-content;
}
.inputLogin:focus-visible {
  outline: none !important;
}

.cardLogin {
  width: 30%;
  margin-left: 5%;
}

.inputLogin {
  width: 100%;
  margin-bottom: 5%;
  border: none;
  border-radius: 5px;
  height: 50px;
  padding-left: 5%;
  border: 1.5px solid #b6b4b4;
  color: #b6b4b4;
}

.inputLogin:focus {
  border: 1.5px solid #3282b8;
}

.inputWithValue {
  color: #3282b8;
  border: 1.5px solid #3282b8;
}

.ShowPasswordIcon {
  color: grey;
  position: absolute;
  top: 14px;
  right: 10px;
  cursor: pointer;
}

.inputLogin:focus::-webkit-input-placeholder {
  color: transparent;
}

.loginBtn {
  width: 100%;
  border: none;
  background: #3282b8;
  color: white;
  height: 50px;
  border-radius: 5px;
  cursor: pointer;
}

.inputLogin::placeholder {
  color: #b6b4b4;
}

.mainBackground {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  padding-top: 6%;
}

/* .loginBox {
  height: 200px;
  width: 40%;
  margin-left: 5%;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background: white;
} */

.reportMediaView::-webkit-scrollbar {
  display: none;
}

.playIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.selectionView {
  height: 220px;
  width: auto;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.imgSelectedView {
  max-width: 400px;
  max-height: 300px;
}

/* ::-webkit-scrollbar {
  display: none;
} */

.reportMediaView {
  overflow: scroll;
  white-space: nowrap;
  display: flex;
  margin-top: 7%;
  margin-bottom: 5%;
}

.plusDiv {
  height: 100px;
  width: 100px;
  object-fit: cover;
  margin-right: 3%;
  background: #d8d6d6;
  border-radius: 3px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.deleteIcon {
  position: absolute;
  right: -10px;
  top: -9px;
  background: white;
  border-radius: 50%;
  border: 2px solid red;
  cursor: pointer;
}

.videoContainer,
.imgContainer {
  display: flex;
  margin-top: 4%;
  margin-bottom: 4%;
  overflow: scroll;
  padding-top: 2%;
}

.imgSelected,
.imgCrouse {
  height: 100px;
  width: 100px;
  object-fit: cover;
  margin-right: 3%;
}

.videoPlayer {
  margin-right: 3%;
}

.uploadVideo {
  background: #3282b8;
  color: white;
  padding: 6px;
  border-radius: 10px;
  cursor: pointer;
}

.dialoginMUI > div > div.MuiPaper-root.MuiDialog-paper {
  overflow-y: visible;
}

.datepickInput {
  width: 89% !important;
}

.react-datepicker__input-container > input {
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  padding: 16.5px 14px;
  border: 1px solid #b9b3b3;
  width: 42%;
  border-radius: 4px;
  font-weight: 200;
}

.react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
  display: none;
}

.react-datepicker__header {
  padding-top: 7% !important;
}

.react-datepicker__navigation.react-datepicker__navigation--previous,
.react-datepicker__navigation.react-datepicker__navigation--next {
  margin-top: 6% !important;
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
  margin-bottom: 4%;
}

.updateBtnDiv {
  display: flex;
  justify-content: end;
  margin-right: 2%;
  margin-top: -10%;
}

#dwnlProfiles {
  width: 250px !important;
}

.dowloadButton > span {
  font-weight: 600 !important;
}

.lelel > h2 {
  color: #212529;
}

.chatFlex {
  display: flex !important;
  align-items: center !important;
  width: auto !important;
}

.apexcharts-datalabels > text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
}

.borderHr {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 2%;
}

.searchIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #909294;
}

.weatherHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #212529;
}

.headingInModal,
.lelel > h2 {
  font-size: 24px !important;
}

.search_view input[type="search"]::placeholder {
  color: #212529;
  opacity: 0.5;
}

.spinnerLoader {
  height: 30px !important;
  width: 30px !important;
}

.chatImg {
  height: 52px !important;
  width: 52px !important;
  border-radius: 50% !important;
}

.BtnGreen {
  border-color: #53b606;
  border: 1px solid #53b606;
  border-radius: 11px;
  padding: 5px 7px;
  cursor: pointer;
  background-color: #53b606 !important;
  color: white;
}

ul.dashboardUl > a > li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.imgIcon {
  border: 1px solid #3282b8;
  padding: 10px;
  border-radius: 50%;
  justify-content: center;
  display: flex;
}

div.title > h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #3282b8;
  margin-bottom: 0px;
}

div.content > p > label,
div.content > p > span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #212529;
}

.dowloadButton {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  color: #212529 !important;
  border: 1.5px solid #212529 !important;
  padding: 8px !important;
  border-radius: 6px !important;
  height: 45px !important;
  width: 210px !important;
}

.addAdminBtn {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;

  line-height: 18px !important;
  background: #3282b8;
  border-radius: 6px;
  padding: 8px !important;
  height: 45px !important;
  border-radius: 6px !important;
  width: 100px !important;
}

.heading > h3 {
  font-size: 26px;
}

.tableHeadings {
  font-family: "Helvetica Neue" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #212529 !important;
}

.tableRowData {
  margin-bottom: 20px;
  height: 60px;
}

.tableCellData {
  font-family: "Helvetica Neue" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 14px !important;
  text-align: center !important;
}

.filterBtn {
  width: 40px !important;
  min-width: 30px !important;
}

div.titlee > h2 {
  margin-bottom: 30px;
}

.downloadButtonWidth {
  width: 250px !important;
}

.cellHeight {
  height: 60px;
}

.searchBeaches > div > div.wrapper {
  border-radius: 0px;
  box-shadow: none !important;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
}

div.wrapper > div > svg {
  display: none;
}

div.muiSelection > div > div.MuiSelect-select {
  padding: 0px !important;
  height: 45px !important;
  align-items: center !important;
  display: flex !important;
  padding-left: 10px !important;
}

.addAdminGrid {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
}

.borderDiv {
  border-bottom: 1px solid #e3e3e3;
  margin-top: 7% !important;
  margin-bottom: 5% !important;
  width: 90%;
  margin: 0 auto;
}

.addNew {
  border: none;
  color: white;
  width: 120px !important;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 30px;
}

.addNewBtn {
  display: flex;
  justify-content: right;
  width: 95%;
}

.popUpHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #212529;
  opacity: 0.5;
}

.popUpDetail {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px;
  color: #212529;
}

.imagesCrousal {
  width: 95%;
  margin: 5px;
  cursor: pointer;
  height: 70px;
  object-fit: contain;
}

.calendarInput {
  padding: 16.5px 14px;
  width: 100%;
  border: 1px solid #e3e3e3;
}

.MuiInputLabel-root {
  margin-top: -4px;
}

.privacy {
  padding: 5% !important;
}

.member-card-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.member-card-profile img {
  width: 50px !important;
  height: 50px !important;
  border-radius: 30px !important;
  margin-bottom: 0px !important;
}

.member-card-name {
  margin-bottom: 0 !important;
  margin-left: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px;
  color: #212529;
  display: flex;
  flex: 1;
  max-width: 200px;
}
.group-listingupdate {
  padding: 11px;
  width: 100%;
}
.no-margin-bottom {
  margin-bottom: 0 !important;
}

.group-view-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.group-view-heading {
  font-size: x-large;
  font-weight: 500;
}

.requests-cont {
  min-height: 300px;
}

.request-card-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid gray;
}
.request-card-image-cont {
  display: flex;
  align-items: center;
}
.group-edit-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 5px;
}
.group-edit-heading {
  font-size: 16px;
  color: black;
  margin-bottom: 0px !important;
}

.group-chat-text-div {
  flex: 1;
  margin-left: 15px;
}

.add-member-btn {
  width: 150px !important;
  height: 40px;
  cursor: pointer;
}

.accept-request-btn {
  width: 100px !important;
  height: 30px;
  background-color: green !important;
  cursor: pointer;
  margin: 0;
}

.reject-request-btn {
  width: 100px !important;
  height: 30px;
  background-color: red !important;
  cursor: pointer;
  margin: 0;
}

.member-card-button {
  width: 150px !important;
  height: 40px !important;
}

.padding-horizontal {
  padding: 0px 10px;
}

.min-height {
  min-height: 80vh;
}

.member-loading-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  flex-direction: column;
}
.no-search {
  height: 22vh;
  margin-bottom: 15px;
}
.users-error-text {
  font-size: 16px;
  color: black;
  opacity: 0.5;
}
.add-member-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.group-chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 20px;
  height: 6vh;
}
.msg-cont {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #b9b3b3;
  height: 8vh;
  align-items: center;
  justify-content: space-between;
}
.text-cont {
  width: 90%;
}
.text-cont input[type="search"]::placeholder {
  color: #212529;
  opacity: 0.5;
}

.text-cont input[type="search"] {
  outline: none;
  border: 0px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: 10px;
  width: 100%;
  height: 50px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
.edit-group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.report-icon {
  width: 10px;
  height: 10px;
}
.alert-icon {
  width: 20px;
  height: 20px;
  filter: brightness(0) invert(1);
  margin-left: 2px;
}

.pointer {
  opacity: 0.5;
  cursor: pointer;
}

li.listPrivacy:hover {
  background-color: transparent !important;
}

.titlePrivacy {
  padding-top: 2%;
}

.break-post-main-div {
  max-width: 60%;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 25px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.break-post-child-div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.break-post-child-child-div {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.break-post-header-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.break-post-row-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 5px;
}

.post-header-name {
  margin-bottom: 0 !important;
  margin-left: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900 !important;
  font-size: 14px !important;
  line-height: 21px;
  color: white;
  display: flex;
}
.post-date-text {
  margin-bottom: 0 !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 10px !important;
  color: white;
}

.post-detail-text {
  margin-bottom: 0 !important;
  margin-left: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 21px;
  color: white;
  display: flex;
}

@media screen and (max-width: 1000px) {
  .cardLogin {
    width: 90%;
    /* margin-left: 5%; */
    margin: 0 auto;
  }

  .rememberMe > p {
    color: white;
  }

  .forgot-password-text {
    color: black;
  }
}
