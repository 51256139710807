.uperbar1 {
  font-weight: 600;
}

.welcomePara {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #212529;
  opacity: 0.6;
}

.headingsDash {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}

div.wrapper > div > input::placeholder {
  color: black;
}

.MuiPaper-root.MuiDrawer-paper {
  min-width: 270px;
}

.scrollmenu::-webkit-scrollbar {
  display: none;
}

div.scrollmenu {
  overflow: scroll;
  white-space: nowrap;
  color: black !important;
}

div.scrollmenu > div {
  display: inline-block;
  text-align: center;
  padding: 14px;
  text-decoration: none;
  border: 2px solid #bfbfbf;
  border-radius: 10px;
  cursor: pointer;
  width: 425px;
}

div.scrollmenu > div.weatherScroll {
  width: 320px !important;
  margin-right: 2%;
}

.imgWeather {
  width: 50px;
  height: 39px;
  margin-left: -10px;
}

.windData {
  border: 2px solid #bfbfbf;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 13px;
  margin-right: 2%;
}

.divData {
  margin-right: 2%;
}

.rightSideChat {
  background: linear-gradient(88deg, #3f3a61 0%, #3f3a64 35%, #8171e8 100%);
  color: white;
}

.msgNames {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgChat {
  margin-top: 30px;
  margin-left: -20%;
}

.blockButton {
  border: none;
  border-radius: 5px;

  padding: 7px 20px;
  font-size: 12px;
  /* padding-left: 10px; */
  /* padding-right: 10px; */
  cursor: pointer;
  background-color: red;
  color: white;
  /* width: 53px; */
  margin-left: 10px;
  height: 27px;
  align-items: center;
  text-align: center;
  display: flex;
}

.unblockButton {
  border: none;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  background-color: green;
  color: white;
  width: 68px;
  margin-left: 10px;
  height: 23px;
  align-items: center;
  text-align: center;
  display: flex;
}

.nameChat {
  font-weight: 500;
  font-size: 10px !important;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #9e9e9e;
}

.dateInConversation {
  display: flex;
  justify-content: center;
  background-color: white;
}

.line {
  border: 0.5px solid #ffffffc4;
  height: 1%;
  width: 40%;
  background-color: #9e9e9e;
}

.lineText {
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #9e9e9e;
  padding: 0% 3% 0% 3%;
  width: 200px;
}

.lineDiv {
  margin-top: 5%;
  margin-bottom: 5%;
  display: flex;
  flex-direction: row;
  height: 0%;
  align-items: center;
  justify-content: center;
}

.MuiDrawer-paperAnchorLeft {
  left: 0;
  right: auto;
  /* background: #3f3a61 !important; */
  background-color: #3282b8 !important;
  font-weight: bolder;
  color: #fff !important;
  max-width: 250px;
  width: 300px;
  transition: ease-in-out 0.3s;
}

/* h3,
h4,
.maindivuser h2 {
  font-family: fantasy !important;
} */

.Main_content {
  margin-left: 270px;
}

/* .seleeect {
  margin: 12px 0px;
} */

.logo {
  padding: 15px;
  background: #5fb8e0;
  font-weight: bolder;
  color: #fff;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
}

.bashboard ul {
  text-decoration: none;
  list-style: none;
  padding: 0;
}

ul {
  /* text-decoration: none;
  list-style: none; */
  padding: 0;
}

a {
  text-decoration: none;
  color: #fff;
}

.bashboard a {
  color: #fff !important;
  font-weight: bolder;
}

.bashboard li {
  width: 100%;
  padding: 14px 48px;
  border-bottom: 1px solid;
}

i.fas.fa-arrow-down {
  margin-left: 60px;
}

li:hover {
  /* background: #585086; */
  /* background-color: #5fb8e0; */
  text-decoration: none;
}

.hoveron {
  /* background: #585086; */
  background-color: #5fb8e0;
  text-decoration: none;
  /* border-top: 1px solid; */
}

li:hover a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.dropbox {
  margin-left: 35px;
  display: none;
}

.dropbox view {
  display: block;
  transition: ease-in-out 1s;
}

.fa-angle-down:before {
  content: "\f107";
  margin-left: 60px;
  cursor: pointer;
}

button.bttn.btn-danger {
  background: #f11515;
  border: 1px solid red;
  border-radius: 11px;
  color: #fff;
  padding: 6px;
  font-size: 11px;
  cursor: pointer;
}

button.bttn.btn-danger:hover {
  background: #780a0a;
  border: 1px solid #780a0a;
}

button.bttn.btnupdate {
  border: 1px solid red;
  border-radius: 11px;
  color: #fff;
  padding: 6px;
  font-size: 11px;
  cursor: pointer;
}

button.bttn.btnupdate:hover {
  background: #780a0a;
  border: 1px solid #780a0a;
}

button:focus {
  outline: 1px dotted;
  outline: none;
}

button.bttn.btnupdate {
  background: #ababab;
  border: 1px solid #ababab;
  border-radius: 12px;
  /* margin-left: 13px; */
  color: #fff;
  cursor: pointer;
}

button.bttn.btnupdate:hover {
  background: #747171;
  border: 1px solid #747171;
  border-radius: 12px;
  /* margin-left: 13px; */
  color: #fff;
  cursor: pointer;
}

.users_table {
  padding: 20px 55px;
}

.top_bar {
  align-items: center;
  padding: 12px 30px;
  color: #fff;
  /* background: #3f3a61; */
  background: #3282b8;
  font-weight: bolder;
}

.top_bar {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.logoutt-btn {
  /* background: #3f3a61; */
  background: #3282b8;
  color: #fff;
  cursor: pointer;
  border: 0px;
  margin-left: 16px;
  padding-right: 0px;
  padding-left: 0px;
  /* box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px; */
  /* box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px,
    rgb(10 37 64 / 35%) 0px -2px 6px 0px inset; */
}

.logoutt-btn:hover {
  /* background: #7c74b3; */
  background-color: #004a7c;
}

.logoDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.brandName {
  font-family: "Satoshi Black" !important;
  font-style: normal;
  font-weight: 900;
  font-size: 16.8044px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #ffffff;
}

.brandNameRegular {
  font-family: "Satoshi Medium" !important;
}

.dropdown {
  position: absolute;
  right: 15px;
  /* padding: 10px; */
  background: #fff;
  border: 1px solid #000;
  color: darkslategrey;
  display: none;
}

.dropdown a {
  color: #3f3a61;
  padding: 26px 22px;
}

.dropdown span:hover {
  color: #fff;
}

.dropdown span {
  padding: 0px 25px;
  color: #336e6e;
  cursor: pointer;
}

.top_bar {
  position: relative;
}

.titlee {
  text-align: center;
  padding: 15px 12px;
}

.dropdown li {
  text-align: start;
  margin: 7px 0px;
}

button.bttn.btn-success {
  border-color: #3282b8;
  border: 1px solid #3282b8;
  border-radius: 11px;
  padding: 5px 7px;
  cursor: pointer;
  background-color: #3282b8 !important;
}

button.bttn.btn-success:hover {
  background-color: #063f65 !important;
  border: 1px solid #063f65;
}

button.bttn.btn-successd {
  border-color: #3f3a61;
  border: 1px solid #3f3a61;
  border-radius: 11px;
  padding: 5px 7px;
  cursor: pointer;
  background-color: #e1e1e1 !important;
}

.centerr {
  text-align: center;
}

.lelel {
  width: 100%;
  height: 100%;
  padding: 9px 43px 50px;
}

.MuiDialog-paperWidthSm {
  width: 70% !important;
}

.lelel h2 {
  margin-bottom: 45px;
}

.lelel label {
  font-weight: 700;
}

.MuiIconButton-label {
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: end !important;
}

.css-1bn53lx {
  width: 100% !important;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter.MuiTableCell-sizeMedium.overfflo.css-1yhpg23-MuiTableCell-root {
  max-width: 343px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reportview {
  display: block;
  justify-content: space-between;
  margin-bottom: 12px;
}

.reportview h6 {
  margin-right: 50px;
  font-weight: 700;
}

.flexxview {
  display: block;
  justify-content: space-between;
  padding: 0;
}

.profile img {
  /* width: 60%;
  max-height: 50%;
  border-radius: 10px; */
  height: 35%;
  width: 35%;
  object-fit: contain;
  border-radius: 10px;
}

.profile {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.group {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.group img {
  height: 50% !important;
  width: 50% !important;
  object-fit: contain;
  margin-bottom: 30px !important;
}

.detaill {
  width: 100%;
}

/* h2 {
  font-family: fantasy;
} */

.reportview p {
  overflow: auto;
  text-overflow: ellipsis;
  width: 100%;
}

.active-hours {
  font-weight: 600;
}

.css-169iwlq-MuiCalendarPicker-root {
  max-height: 170px !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiTableContainer-root.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  /* box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px; */
  border: 1px solid #e3e3e3;
  border-radius: 8px 8px 0px 0px;
}

.card_div {
  display: flex;
  justify-content: center;
}

.cardds hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  /* border-top: 1px solid rgb(255 255 255); */
  border-top: 1px solid #212529;
  opacity: 0.1;
}

.cardds {
  padding: 33px;
  width: 100%;
  box-shadow: 0px 6px 26px rgba(5, 29, 61, 0.08);
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  border: 1px solid #e9e9ea;
  border-radius: 10px;
  color: #fff;
}

.cardds .title {
  padding: 25px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_bar {
  padding: 15px;
}

/* .cardds.one {
  background-image: linear-gradient(to right, #283487, #8415b3);
}

.cardds.two {
  background-image: linear-gradient(to right, #ec8c23, #f4cb2c);
}

.cardds.three {
  background-image: linear-gradient(to right, #8d42a2, #d75399);
} */

.title i {
  font-size: 25px;
  border: 1px solid;
  padding: 8px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
}

span.MuiCircularProgress-root.MuiCircularProgress-indeterminate.MuiCircularProgress-colorPrimary.css-18lrjg1-MuiCircularProgress-root {
  position: absolute;
  right: 40%;
  top: 50%;
}

span.MuiCircularProgress-root.MuiCircularProgress-indeterminate.MuiCircularProgress-colorPrimary.circulerr.css-18lrjg1-MuiCircularProgress-root {
  position: initial;
}

/* .users_table .loaderr {
  position: absolute;
  right: 25%;
  top: 60%;
} */
.loaderr img {
  width: 100px;
}

/* .loaderrs img {
  width: 200px;
} */
.d-flex.justify-content-center.h-100 {
  height: 100% !important;
}

.lgoo img {
  height: 30px;
  width: 30px;
  margin-right: 15px;
  border-radius: 30px;
}

a.lgoo {
  display: flex;
  justify-content: start;
  align-items: end;
}

.MuiIconButton-root:hover {
  background-color: transparent !important;
}

.useruPDATE {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
}

.maindivuser {
  padding: 12px;
}

img.profille {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.listingupdate {
  padding: 11px;
  width: 50%;
}

.listingupdate label {
  font-weight: 700;
}

.listingupdate input {
  /* margin-left: 20px; */
}

.listingupdate img {
  width: 75px;
}

.imgflex {
  display: flex;
  padding: 15px 0px;
}

.flexxiidays {
  display: flex;
  flex-wrap: wrap;
}

.bioflex {
  width: 50%;
}

span.inerflexi {
  margin-left: 16px;
}

h6.inerflexi {
  text-transform: capitalize;
}

span.active-hours {
  font-weight: 500;
}

.devideiid {
  width: 150px;
}

label.spaace {
  margin-right: 20px;
}

.excle_btn {
  /* text-align: right;
  padding: 8px;
  padding-bottom: 70px; */
  text-align: right;
  padding: 8px;
  padding-bottom: 10px;
  padding-top: 15px;
  padding-right: 15px;
}

.search_view input[type="search"] {
  outline: none;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 5px 30px;
  width: 100%;
  height: 44px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 20px;
}

/* .search_view {
  padding: 10px;
} */

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.excle_innbtn {
  border: 2px solid #3f3a61;
  margin-right: 10px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  background: #488fbf;
  color: white;
  font-weight: 700;
  margin-left: 10px;
  box-shadow: none;
}

.btnn {
  text-align: right;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.filterr.css-i4bv87-MuiSvgIcon-root {
  cursor: pointer;
}

.statuss {
  display: flex;
  align-items: end;
}

tr.MuiTableRow-root.css-34nofg-MuiTableRow-root:hover {
  background: #6983887a;
}

.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.filterr.css-i4bv87-MuiSvgIcon-root {
  margin-left: 6px;
}

.flex_images img {
  width: 95%;
  margin: 5px;
  cursor: pointer;
}

.flex_images video {
  object-fit: cover;
  display: block;
  width: 95%;
  height: 95%;
  margin: 5px;
  cursor: pointer;
}

.video-container {
  text-align: center;
  width: 45px;
  height: 60px;
}

.file-container {
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-container img {
  border-radius: 12px !important;
}

.report-video-container {
  text-align: center;
  width: 75px;
  height: 60px;
}

.report-video-container video {
  object-fit: cover;
  display: block;
  width: 95%;
  height: 95%;
}

put.MuiSelect-nativeInput.css-1k3x8v3 {
  width: 100%;
}

.MuiFormControl-root.MuiTextField-root.css-i44wyl {
  display: block;
  /* width: 100% !important; */
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-1v4ccyo {
  width: 100% !important;
}

.reportmainview {
  display: block;
  justify-content: space-between;
}

.reportimg {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.reportcontent {
  width: 100%;
}

img.rpimg {
  width: 100%;
}

.absol {
  position: absolute;
  top: -16px;
  right: -5px;
  cursor: pointer;
  z-index: 999;
}

label.spaace {
  position: relative;
}

label.spaace {
  position: relative !important;
}

.rell {
  position: relative;
}

.absol
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  color: #923030;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter.MuiTableCell-sizeMedium.avail_green.css-1yhpg23-MuiTableCell-root {
  background: darkgreen;
  color: white;
}

span.avail_green {
  background: green;
  color: #fff;
  padding: 6px;
  border-radius: 5px;
  font-size: 12px;
}

span.out_red {
  background: #910023;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
}

.indv {
  padding: 6px !important;
}

/* td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter.MuiTableCell-sizeMedium.css-1yhpg23-MuiTableCell-root {
  width: 9.1vw !important;
} */
.active,
.deactive {
  display: flex !important;
  align-items: end;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter.MuiTableCell-sizeMedium.css-1yhpg23-MuiTableCell-root {
  max-width: 9.1vw !important;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 9.1vw !important;
}

.css-1a4b21o {
  max-width: 9.1vw !important;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 9.1vw !important;
}

.css-1brzq0m {
  overflow-x: hidden;
  width: 320px;
  max-height: 170px !important;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
}

.deactive {
  pointer-events: none;
  opacity: 0.6;
}

input.form-control {
  height: 53px !important;
  width: 265px !important;
}

.main1 {
  padding: 0px;
  width: 100%;
  margin: 10px;
  margin-left: 0px;
  /* box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px; */
  border-radius: 10px;
  color: #fff;
}

.cardds {
  height: 60%;
}

div#chart {
  padding-top: 33px;
  height: 268px;
}

div#standard-select-currency\ genderr {
  color: #000 !important;
  background: #ffff;
  min-width: 313px !important;
}

.flexxview.reportsfeed {
  display: block;
  width: 100%;
}

.loaadds {
  text-align: center;
}

.reportsfeed .detaill {
  width: 100%;
}

p.jusstify {
  text-align: justify;
}

.css-yf8vq0-MuiSelect-nativeInput {
  color: #fff;
}

/* label#standard-select-currency\ genderr-label {
  color: #fff;
} */
/* svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: #fff;
} */

/* -----weather-------- */
.mainFlex {
  display: flex;
  flex-wrap: wrap;
}

.weatherReginal {
  color: #000;
  width: 19%;
  border-radius: 13px;
  padding: 12px;
  border: 2px solid #bfbfbf;
  margin: 4px;
}

.WeatherOne {
  margin: 20px 0px;
}

p.dateTime {
  color: #999696;
  font-weight: 800;
}

p.statuss {
  color: #4b9cea;
  font-weight: 800;
}

p.heights {
  color: #404d4d;
  font-weight: 600;
}

.center {
  text-align: center;
}

.center img {
  width: 20%;
}

.spinss img {
  width: 100%;
}

.spins img {
  width: 50%;
}

p.interval {
  color: #797373;
  font-weight: 700;
}

.wind {
  border-radius: 13px;
  padding: 12px;
  border: 2px solid #bfbfbf;
  margin: 4px;
}

.windTime {
  border-radius: 13px;
  padding: 8px;
  border: 2px solid #bfbfbf;
  margin: 1px;
  text-align: center;
}

p.interval span img {
  width: 10%;
}

.windInerFlex p {
  margin: 4px 0px;
}

.windInerFlex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.windImage img {
  width: 50%;
}

.temptr {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0px;
}

.tempImg img {
  width: 60%;
  border-radius: 12px;
}

p.weaText {
  font-size: 12px;
  color: grey;
  font-weight: 600;
  margin-bottom: 2px;
}

p.scale {
  font-size: 24px;
  font-weight: 500;
}

.fflextext {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.maintemp {
  padding: 10px;
}

.Sunss {
  margin-top: 20px;
}

.fflextext .text {
  color: gray;
  font-weight: 500;
}

.fflextext .timee {
  color: #616161;
  font-weight: 600;
}

.tempImg {
  width: 50%;
}

.main1.flexicard {
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
}

div#charts {
  width: 33%;
  border: 2px solid #bfbfbf;
  border-radius: 12px;
  margin: 2px;
}

.main_bar.apexis {
  padding: 0px;
}

.detailss .MuiDialog-paperWidthSm {
  max-width: 100%;
}

/* -------Chat--------- */
.mainChat {
  display: flex;
  /* padding: 20px; */
}

.sideChat ul li {
  padding: 10px 6px;
  border-bottom: 1px dotted #9e9e9e;
}

.sideChat .inerflex span {
  font-size: 12px;
}

.flexTwoWay {
  display: flex;
  /* justify-content: space-between; */
}

.flexii {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 20px 0px;
}

.temsChat img {
  width: 51px;
  height: 50px;
  border-radius: 50px;
  margin-right: 12px;
}

.temsChat:hover {
  box-shadow: rgba(5, 9, 12, 0.3) 0px 1px 2px 0px,
    rgba(3, 12, 19, 0.15) 0px 2px 6px 2px;
  border-radius: 12px;
  cursor: pointer;
}

.temsChat {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 12px;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.temsChat .custm {
  text-align: center;
  padding: 0px;
  border-radius: 50px !important;
  height: 50px;
  width: 50px;
  font-size: 27px !important;
}

.nameee {
  color: #fefefe;
  background: #594f86;
  font-weight: 600;
  height: 33px;
  width: 33px;
  border: 1px solid;
  border-radius: 50px;
  text-align: center;
  padding: 0px;
  font-size: 20px !important;
  margin-right: 5px;
}

.sideChat ul li:hover .borr {
  border: 1px solid #ffffff;
}

.activeSiide {
  color: white;
  /* border-radius: 8px; */
  cursor: pointer;
  background: #3f3a61;
}

.activeSiide .borr {
  border: 1px solid #ffffff;
}

.borr {
  border: 1px solid #3f3a61;
  margin: 0px 12px;
}

.sideChat {
  /* padding: 10px; */
  /* border: 1px solid black; */
  border-radius: 12px;
  /* margin-right: 8px; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 30%;
  height: 90vh;
  overflow: scroll;
}

.leftChat span {
  position: absolute;
  bottom: -22px;
  left: 55px;
  font-size: 12px;
}

.leftChat.rightt span {
  right: 60px;
  left: auto;
}

.conversation {
  /* padding: 12px; */
  /* border: 1px solid black; */
  border-radius: 12px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.topBar {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dotted;
  align-items: center;
  padding: 20px 10px;
}

.flexTwoWay img {
  width: 33px;
  border-radius: 50px;
  height: 33px;
}

.leftbar {
  width: 100%;
}

.inerflex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inerflex img {
  margin-right: 3px;
}

.sideChat ul li:hover {
  color: white;
  /* border-radius:   8px; */
  cursor: pointer;
}

.inerflex {
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  width: 50%;
}

.temsChat.opaa {
  opacity: 0.5;
  border-radius: 12px;
}

.inerflex img {
  margin-right: -14px;
}

.inerflex.bari {
  justify-content: center;
}

.chatcontent img {
  width: 50%;
}

.leftChat {
  display: flex;
  align-items: unset;
  /* width: 50%; */
  margin-bottom: 30px;
  position: relative;
}

.chatimg img {
  border-radius: 50px;
  width: 40px;
  height: 40px;
}

.inboxChat {
  padding: 25px 10px;
  height: 80vh;
  overflow: scroll;
}

.rightbar span {
  color: rgb(160, 9, 9);
  cursor: pointer;
}

.chatcontent {
  border-radius: 8px;
  padding: 12px;
  background: #f7f7f7;
  border-radius: 0px 6px 6px 6px;
}

.chatcontent p {
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
  font-weight: 500;
  margin-bottom: 0px;
}

.leftChat.rightt {
  justify-content: right;
  position: relative;
}

.chatimg.rightImg {
  margin-left: 12px;
}

/* -------------Chat-Jezzi--------- */

li.activeSiide {
  background: rgba(247, 247, 247, 1);
  color: #000;
  border-bottom: 0px !important;
}

.flexTwoWay img {
  width: 40px;
  border-radius: 50px;
  height: 40px;
}

.inerflex {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 30%;
}

.msgNames span {
  color: #212529;
  font-weight: 500;
  /* margin-left: 14px; */
}

.sideChat h2 {
  text-align: left;
}

.topBar {
  display: flex;
  border-bottom: 1px solid rgba(227, 227, 227, 1);
  padding: 20px 10px;
}

.inboxChat {
  padding: 25px 40px;
}

li.nonActive {
  margin: 12px;
}

.leftNamee {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 12px;
}

.leftNamee h2 {
  font-size: 12px;
  color: #212529;
  opacity: 0.8;
  margin: 0px;
}

.leftNamee p {
  font-size: 9px;
  color: rgb(153 153 153);
  margin-left: 12px;
  margin-bottom: 0px;
}

.group-msg-name {
  font-size: 15px;
  color: rgb(153 153 153);
  margin-bottom: 1px;
}

.text-right {
  text-align: right;
}

.group-msg-date {
  margin-top: 2px;
}

.mainfl {
  max-width: 40%;
  margin-left: 12px;
}

.chatimg img {
  width: 50px;
  height: 50px;
}

.chatcontent p {
  font-weight: 300;
  color: #212529 !important;
  opacity: 0.8;
  font-size: 13px;
}

.rightSideChat {
  color: #fff !important;
  background: #3282b8 !important;
  border-radius: 6px 0px 6px 6px !important;
}

.rightSideChat p {
  color: #ffff !important;
}

.mainBox {
  padding: 5px 20px;
}

.mainBox {
  padding: 5px 20px;
}

/* .flexTwoWay.toospp {
  justify-content: space-between;
} */

.flexTwoWay.toospp img {
  width: 70px;
  height: 70px;
}

.rightt .leftNamee {
  justify-content: end;
}

.NameHour {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.timeHour span {
  font-size: 10px;
  color: #212529;
  opacity: 0.5;
}

li.activeSiide {
  border-bottom: 0px !important;
  padding: 10px 20px !important;
}

.mainBox {
  padding: 5px 20px;
}

/* .flexTwoWay.toospp {
  justify-content: space-between;
} */

.flexTwoWay.toospp img {
  width: 80px;
  height: 80px;
}

.productCardText {
  padding: 28px 12px;
}

.productCard {
  background: rgba(247, 247, 247, 1);
  border: 0px !important;
}

.conversation {
  border-left: 1px solid rgba(227, 227, 227, 1);
  border-radius: 0px;
  box-shadow: none;
}

.sideChat {
  border-radius: 0px;
  box-shadow: none;
}

.flexTwoWay.toospp img {
  margin-right: 10px;
  margin-left: 0px;
}

.editflex {
  display: flex;
  align-items: center;
}

.justify-right {
  justify-content: flex-end;
}

/* -------------Chat-Jezzi--------- */

@media only screen and (max-width: 600px) {
  .MuiDrawer-paperAnchorLeft {
    max-width: 0px;
    width: 30px;
    transition: ease-in-out 0.3s;
  }

  .Main_content {
    margin-left: 0;
  }

  .users_table {
    padding: 6px 14px;
  }

  .card_div {
    display: block;
  }

  div#chart {
    height: auto;
  }
}

.css-1ndpvdd-MuiTableCell-root {
  padding: 0px !important;
  padding-bottom: 5px !important;
}

.css-1gzy9y4 {
  padding: 0px !important;
  padding-bottom: 5px !important;
}

@media only screen and (max-width: 1400px) {
  div#charts {
    width: 32%;
  }

  .css-1ndpvdd-MuiTableCell-root {
    padding: 0px !important;
    font-size: 12px !important;
  }

  .css-1gzy9y4 {
    padding: 0px !important;
    font-size: 12px !important;
  }

  .Main_content {
    margin-left: 270px;
  }

  .mainContent {
    width: 95%;
    margin: 0 auto;
  }

  .users_table {
    padding: 20px 5px;
    width: 92%;
    margin: 0 auto;
  }

  .MuiDrawer-paperAnchorLeft {
    left: 0;
    right: auto;
    /* background: #3f3a61 !important; */
    background-color: #3282b8 !important;
    font-weight: bolder;
    color: #fff !important;
    max-width: 200px;
    width: 300px;
    transition: ease-in-out 0.3s;
  }

  .css-1yhpg23-MuiTableCell-root {
    padding: 8px !important;
  }

  .css-1a4b21o {
    padding: 8px !important;
  }

  .bashboard li {
    width: 100%;
    padding: 16px 30px;
    border-bottom: 1px solid;
    font-weight: 500;
    padding-left: 45px;
  }
}

.apexcharts-theme-light {
  fill: #0000 !important;
  color: black !important;
}

.productCard {
  display: flex;
  border: 1px solid gray;
  /* padding: 10px; */
  border-radius: 10px;
  margin-bottom: 3%;
  cursor: pointer;
  height: auto;
}

.wrapper {
  z-index: 1 !important;
}

.selectedMsg {
  padding: 10px;
  margin-left: 10px;
  border: 2px solid rgb(232, 228, 226);
  border-radius: 10px;
  min-width: 50px;
  width: auto;
  box-shadow: 5px 7px 4px -4px #dad2c2 !important;
  background-color: white;
}

.msgContainer {
  margin-top: 5% !important;
  display: flex;
  justify-content: center;
  padding: 20px;
  border: 1px solid #e8e4e2;
  width: 55%;
  justify-content: center;
  margin: 0 auto;
  border-radius: 10px;
  background-color: #faf6f3;
}

.editMsg {
  height: 150px;
  margin: 0 auto;
  display: flex;
  border-radius: 10px;
  border: 2px solid #e8e4e2;
  width: 100%;
  padding: 10px;
}

.buttonMsgdelete {
  background: rgb(198, 50, 6);
  width: 150px;
  margin: 0px auto;
  margin-top: 5% !important;
}

.reportCard {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  cursor: pointer;
}

.imgReportCard {
  border-radius: 50%;
  height: 50px;
  margin-right: 10px;
  width: 50px;
  object-fit: cover;
}

.MuiTableCell-sizeMedium {
  max-width: fit-content !important;
}
